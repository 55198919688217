import React from "react";
import { SearchIcon } from "@heroicons/react/outline";

const SimpleSearch = ({ onSubmit }) => {
  return (
    <form
      className="mt-4"
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit(event.target[0].value);
      }}
    >
      <label>
        <input
          type="text"
          name="Search"
          className="w-3/4 py-3 font-sans md:w-10/12"
          placeholder="'Finance Reform'"
        />
      </label>
      <button
        type="submit"
        className="!inline-flex  !space-x-4 btn btn-red items-center border-b-red-400 border-b-2 hover:border-b-red-500 !pt-2"
      >
        <SearchIcon className="w-4 mr-2 text-white" />
        Search
      </button>
    </form>
  );
};

export default SimpleSearch;
