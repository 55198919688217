import * as React from "react"
import LayoutDark from "../containers/LayoutDark"
import SimpleSearch from "../common/search/SimpleSearch"
import VolumeOfEmails from "../common/charts/VolumeOfEmails"
import { StaticImage } from "gatsby-plugin-image"
import { Link, navigate } from "gatsby"
import ChartTabs from "./home/components/ChartTabs"
import Seo from "../containers/SEO"
// import Documentation from '../downloads/corpus_documentation_v1.0.pdf'
import senderNames from '../data/getAllSenderNames.json'



function HeroImage() {
  return <StaticImage src="../images/election-emails.jpg" alt="" />
}


const IndexPage = () => {
  return (
    <LayoutDark>
      <Seo title="2020 U.S. Election" />
      <section className="relative pb-0 bg-dark">

        <div className="container !py-0">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-20">
            <div className="flex flex-col justify-center mb-6 text-white">
              <h1>2020 U.S. Election Emails</h1>
              <p>As of Sep 7 2021, the database contains ~948,034 emails from 16082 senders. Request access to full dataset</p>
              <div className="z-10 flex gap-4">
                <a href='https://journals.sagepub.com/doi/full/10.1177/20539517221145371' className="text-white transition border-blue-400 btn btn-blue" rel="nooppener" target="_blank">Read the Paper</a>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdcgjZo-D1nNON4d90H2j0VLtTdxiHK6Y8HPJSpdRu4w5YILw/viewform" className="text-white transition btn btn-outline" target="_blank" rel="noopener">Request the Data</a>
                <a href="https://github.com/citp/election-emails" className="text-white transition btn btn-outline" target="_blank" rel="noopener">Get the Code</a>
              </div>
            </div>
            <div className="z-10 flex">
              <HeroImage />
            </div>
          </div>
        </div>
        <div className="bg-grid bg-grid-1"></div>
        <div className="bg-grid bg-grid-2"></div>
      </section>
      <section className="py-0 bg-dark" id="search-database">
        <div className="container bg-light">
          <div className="max-w-4xl">
            <h2 className="border-b-4 border-red-400">Search the Database of State and Federal 2020 Election Emails</h2>
            <p className="text-sm text-gray-400">As of Sep 7 2021, the database contains ~948,034 emails from {senderNames.length} senders. <a href="https://docs.google.com/forms/d/e/1FAIpQLSdcgjZo-D1nNON4d90H2j0VLtTdxiHK6Y8HPJSpdRu4w5YILw/viewform" target="_blank" rel="noopener">Request access to full dataset</a></p>
            <SimpleSearch onSubmit={(data) => {
              navigate(
                `/emails/?q=${data}&size=n_20_n`,
                { replace: true }
              )
            }}/>
          </div>
        </div>
      </section>
      <div>
        <div className="container bg-light !pt-0">
          <VolumeOfEmails />
        </div>
      </div>
      <section className="py-0 text-left">
        <div className="container">
          <h2 className="border-b-4 border-blue-400">Summary of main findings</h2>
          <ul className="text-left">
            <li className="max-w-none">• We found six tactics that senders use to manipulate recipients into opening emails. The typical sender used at least one manipulative tactic in about 42% of their emails. Most senders — 99% — use them at least occasionally.</li>
            <li className="max-w-none">• We found 322 entities that shared our email address with other entities but the majority (133/322) had no privacy policy and only about a quarter (77/200) disclosed their email sharing in the privacy policy.</li>
          </ul>
          <ChartTabs />
          <small>* These graphs are computed using keywords from our topic model.</small>
        </div>
      </section>
      <section className="flex py-0" id="data-request">
        <div className="container grid inline-block gap-8 bg-primary -m-14 lg:grid-cols-2">
          <div className="max-w-lg mx-auto text-center">
              <h2>Data Request</h2>
             <p>A preliminary release of the data is now available for download below. We will release additional data in due course.</p>
            <div className="block mb-2">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSdcgjZo-D1nNON4d90H2j0VLtTdxiHK6Y8HPJSpdRu4w5YILw/viewform" className="btn btn-red" target="_blank" rel="noopener">Request the Data</a>
            </div>
            <a href={'/downloads/corpus_documentation_v1.0.pdf'} className="text-sm" rel="noopener" target="_blank">Data release v1.0 documentation</a>
          </div>
          <div className="max-w-md mx-auto xl:max-w-lg">
             <h2 className="text-center">Cite the paper</h2>

             <pre className="p-4 overflow-y-scroll text-sm bg-light">

              @article {'{'}doi:10.1177/20539517221145371,<br />
              title        = {'{'}Manipulative tactics are the norm in political emails: Evidence from 300K emails from the 2020 US election cycle{'}'},<br />
              author       = {'{'}Arunesh Mathur and Angelina Wang and Carsten Schwemmer and Maia Hamin and Brandon M Stewart and Arvind Narayanan{'}'},<br />
              year         = 2023,<br />
              journal      = {'{'}Big Data \& Society{'}'},<br />
              volume       = 10,<br />
              number       = 1,<br />
              pages        = 20539517221145371,<br />
              doi          = {'{'}10.1177/20539517221145371{'}'},<br />
              url          = {'{'}https:/doi.org/10.1177/20539517221145371{'}'},<br />
              eprint       = {'{'}https:/doi.org/10.1177/20539517221145371{'}'}<br />
              {'}'}


             </pre>
          </div>
        </div>
      </section>
    </LayoutDark>
  )
}

export default IndexPage
