import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush
} from 'recharts'
import data from '../../data/volumeOfEmails.json'
// const data = [
//   {
//     name: 'Jan 1, 2019',
//     shortName: 'J',
//     'Republican Party': 3000,
//     'Democratic Party': 2400,
//     Other: 400
//   },
//   {
//     name: 'Jan 1, 2020',
//     shortName: 'J',
//     'Republican Party': 3000,
//     'Democratic Party': 2400,
//     Other: 400
//   },
//   {
//     name: 'Feb',
//     shortName: 'F',
//     'Republican Party': 2800,
//     'Democratic Party': 2398,
//     Other: 210
//   },
//   {
//     name: 'Mar',
//     shortName: 'M',
//     'Republican Party': 2000,
//     'Democratic Party': 2800,
//     Other: 290
//   },
//   {
//     name: 'Apr',
//     shortName: 'A',
//     'Republican Party': 2780,
//     'Democratic Party': 3908,
//     Other: 200
//   },
//   {
//     name: 'May',
//     shortName: 'M',
//     'Republican Party': 3000,
//     'Democratic Party': 2398,
//     Other: 421
//   },
//   {
//     name: 'Jun',
//     shortName: 'J',
//     'Republican Party': 2390,
//     'Democratic Party': 4000,
//     Other: 250
//   },
//   {
//     name: 'Jul',
//     shortName: 'J',
//     'Republican Party': 4000,
//     'Democratic Party': 2400,
//     Other: 240
//   },
//   {
//     name: 'Aug',
//     shortName: 'A',
//     'Republican Party': 4780,
//     'Democratic Party': 4908,
//     Other: 200
//   },
//   {
//     name: 'Sep',
//     shortName: 'S',
//     'Republican Party': 5490,
//     'Democratic Party': 5500,
//     Other: 300
//   },
//   {
//     name: 'Oct',
//     shortName: 'O',
//     'Republican Party': 6880,
//     'Democratic Party': 6008,
//     Other: 900
//   },
//   {
//     name: 'Nov',
//     shortName: 'N',
//     'Republican Party': 7500,
//     'Democratic Party': 9800,
//     Other: 2290
//   },
//   {
//     name: 'Dec',
//     shortName: 'D',
//     'Republican Party': 4000,
//     'Democratic Party': 2400,
//     Other: 240
//   }
// ]

const DataFormater = (number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toString() + 'B'
  } else if (number > 1000000) {
    return (number / 1000000).toString() + 'M'
  } else if (number > 1000) {
    return (number / 1000).toString() + 'K'
  } else {
    return number.toString()
  }
}

const BarGraph = () => {
  return (
    <>
      <div>
        <ResponsiveContainer width='100%' height='100%'>
          <BarChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 50,
              right: 0,
              left: 0,
              bottom: 45
            }}
          >
            <CartesianGrid vertical={false} stroke='#d8d8d8' />
            <XAxis dataKey='name' angle={-45} textAnchor="end" height={50}  />
            <YAxis orientation='left' tickFormatter={DataFormater} />
            <Tooltip
              labelStyle={{
                color: '#605757',
                textTransform: 'uppercase',
                fontWeight: '600',
                fontSize: '.875rem',
                letterSpacing: '.05rem',
                borderBottom: '2px solid #f0f0f0',
                textAlign: 'center'
              }}
              contentStyle={{
                textTransform: 'uppercase',
                fontWeight: '600',
                fontSize: '.875rem',
                letterSpacing: '.05rem'
              }}
            />
            <Legend
              layout='horizontal'
              verticalAlign='top'
              align='center'
              height={50}
              iconSize={16}
              iconType='rect'
            />

            <Bar dataKey='Democratic Party' stackId='a' fill='#376996' />
            <Bar dataKey='Republican Party' stackId='a' fill='#AD3F31' />
            {/* <Bar dataKey='Nonpartisan' stackId='a' fill='#D28466' /> */}
            <Bar dataKey='Other' stackId='a' fill='#3B1858' />
            <Brush dataKey='name' data={data} startIndex={0} endIndex={27} y={350} height={25} stroke="#a6a397"/>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export default BarGraph
