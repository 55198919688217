import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../common/ui/PageHeader'
import PageFooter from '../common/ui/PageFooter'
import "@fontsource/nunito-sans/200.css"
import "@fontsource/nunito-sans/300.css"
import "@fontsource/nunito-sans/600.css"
// import '../styles/main.scss'

function LayoutDark({ children }) {
  return (
    <>
    <div className="page-dark">
      <PageHeader />
      {/* <Example /> */}
      <main id="pageContent" className="prose xl:prose-xl max-w-none">{children}</main>
      <PageFooter />
      </div>
    </>
  )
}

LayoutDark.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutDark

