import React from 'react'
import { Link } from "gatsby"
import BarGraph from './BarGraph';


const VolumeOfEmails = () => {
  return (
    <div>
      <h2>Volume of emails</h2>
      <div className="mx-auto text-center max-w-100">
        <BarGraph />

        <Link to="/emails/" className="btn btn-red">Search Campaigns & Organizations</ Link>

      </div>
    </div>
  )
}

export default VolumeOfEmails
